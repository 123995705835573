<template>
    <div class="o365-header" ref="headerContainerRef">
        <!-- @slot Shared absolute components like bulkUpdate -->
        <slot name="misc"></slot>

        <div class="w-100 vertical-scrollbar-gutter">
            <div class="w-100 d-flex flex-column" ref="animationContainerRef">
                <!-- @slot Container above the header, typically used by group by container -->
                <slot name="top"></slot>

                <!-- HEADER ROWS CONTAINER -->
                <BaseHeaderRow>
                    <template v-if="$slots.headercell" #headercell="scope">
                        <slot name="headercell" v-bind="scope"></slot>
                    </template>
                </BaseHeaderRow>

                <!-- NEW RECORDS PANEL -->
                <slot name="newrecords"></slot>

                <!-- FILTER ROWS -->
                <slot v-if="!gridControl.props.disableFilterRow" name="filterRow">
                    <BaseHeaderRow rowClass="o365-header-row bg-light-subtle">
                        <template #headercell="scope">
                            <slot name="filtercell" v-bind="scope">
                                <BaseFilterCell :col="scope.col" />
                            </slot>
                        </template>
                    </BaseHeaderRow>
                </slot>

            </div>

        </div>

    </div>
</template>

<script lang="ts">
export interface IBaseHeaderProps {
    animatedContainer?: boolean;
};
</script>

<script setup lang="ts">
import useBaseGridControl from 'o365.vue.composables.Grid.BaseControlInject.ts';
import useGridErrorHandler from 'o365.composables.Grid.ErrorHandler.ts';

// System
import { ref, defineAsyncComponent, onMounted, onBeforeUnmount } from 'vue';

// Async components
const BaseFilterCell = defineAsyncComponent(() => import('o365.vue.components.Grid.BaseFilterCell.vue'));
const BaseHeaderRow = defineAsyncComponent(() => import('o365.vue.components.Grid.BaseHeaderRow.vue'));

const props = defineProps<IBaseHeaderProps>();


useGridErrorHandler();

const headerContainerRef = ref<HTMLElement>(null);
const gridControl = useBaseGridControl();

const GridHeader = await gridControl.value.getExtension('header');
const headerControl = GridHeader.extend(gridControl.value);

// Auto animate setup for header container
let animationContainerRef = null
if (props.animatedContainer) {
    const { useAutoAnimate } = await import('auto-animate');
    const [animationRef] = useAutoAnimate({
        disrespectUserMotionPreference: true
    });
    animationContainerRef = animationRef;
}

onMounted(() => {
    headerControl.handleMounted({
        headerContainer: headerContainerRef.value
    });
});

onBeforeUnmount(() => {
    headerControl.destroy();
});
</script>
